import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { BaseService } from 'src/services/BaseService';
import { DataEditService } from 'src/services/Data+EditService';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-upload-documents',
  templateUrl: './upload-documents.component.html',
  styleUrls: ['./upload-documents.component.css']
})
export class UploadDocumentsComponent implements OnInit {


  customerRegistered: boolean = false;
  customerId: any;
  documentsTobeUploaded = [];
  addonDocumentsToBeUploaded = [];
  addOns;

  selectedFile: File
  previews = {};
  uploadedImage: any;

  uploadingImages = false

  compressedImageFiles = [];

  customerRegisteredData: any

  addOnCustomerDocumentDetails = []

  uploadedId = []

  constructor(private http: HttpClient, private router: Router, public sanitizer: DomSanitizer, private ng2ImgMax: Ng2ImgMaxService, private _baseService: BaseService, private dataEditService: DataEditService,private route:ActivatedRoute) { }

  ngOnInit() {
    if (this.dataEditService.backbuttonatUploadClicked)
    {
      this.router.navigateByUrl('/T&C');
    }
    this.customerRegistered = true;
    this.SetUpDocumentsView();
  }

  RegisterCustomer() {
    if (this.compressedImageFiles.length <= 0) {
      alert("No documents selected.Please select documents to upload")
      return false
    }

    var allMandatoryDocumentsReadyToupload = this.CheckIfAllMandotryDocumentsAreSelected()
    if (!allMandatoryDocumentsReadyToupload) {
      alert("Please Upload all mandatory documents for approval. Mandatory Documents are marked with an *")
      return false
    }

    this.customerRegistered = false

    setTimeout(() => {
      let customerAddPostData = this.dataEditService.customer_add_postbody
      if(customerAddPostData.customers[0].email == ""){
        delete customerAddPostData.customers[0].email
      }
      this._baseService.post('/customers', this.dataEditService.customer_add_postbody).subscribe((data: any) => {
        if (data.status.status) {
          // this.customerRegistered = true;
          this.dataEditService.responseData = data
          this.customerRegisteredData = data.customers[0]
          
          //getting ready for documents upload
          var customer = this.dataEditService.customer;
          this.customerId = data.customers[0].id
          this.addOns = customer.addOnCustomers
          this.documentsTobeUploaded = []
          this.addonDocumentsToBeUploaded = []
          data.customers[0].documents.map((document: any) => {
            if (document.docTypeId <= 46 || document.docTypeId == 47 || document.docTypeId == 48 || document.docTypeId == 51)
              document.mandatory = true
            else
              document.mandatory = false

              this.compressedImageFiles.map((image, index) => {
                if(document.docTypeId == image.document.docTypeId){
                  let identifier = image.identifier.split("-")
                  if(identifier[1] == "primary"){
                    this.compressedImageFiles[index].document.url = "customers/" + this.customerId + "/upload?docId=" + document.id
                  }else{
                    if(this.addOnCustomerDocumentDetails[document.addOnId] == undefined){
                      this.addOnCustomerDocumentDetails[document.addOnId] = []
                    }
                    if(this.addOnCustomerDocumentDetails[document.addOnId][document.docTypeId] == undefined){
                      this.addOnCustomerDocumentDetails[document.addOnId][document.docTypeId] = ""
                    }
                    this.addOnCustomerDocumentDetails[document.addOnId][document.docTypeId] = document.id
                  }
                }
              })
              this.documentsTobeUploaded.push(document)

              //feeding Consent Id
              if(document.docTypeId == 52){
                this.dataEditService.concentUploadId = document.id
              }
              //feeding customer id
              this.dataEditService.customerId = data.customers[0].id
          })

          setTimeout(() => {
              this.UploadAllFiles()
          },300)

        }else if(data.status.code == 1037){
          alert('Duplicate addon mobile number,You will be redirected to enter add on details again');
            this.router.navigateByUrl('/add-on-details')
        }
      })
    }, 1000)

  }

  SetUpDocumentsView() {
    var customer = this.dataEditService.customer;
    // this.customerId = customer.id
    this.addOns = customer.addOnCustomers;
    customer.documents.map((document: any) => {
      if (document.docTypeId <= 46 || document.docTypeId == 47 || document.docTypeId == 48 || document.docTypeId == 51)
        document.mandatory = true
      else
        document.mandatory = false

      if (document.docTypeId != 48 && document.docTypeId != 51 && document.docTypeId != 54) {
        this.documentsTobeUploaded.push(document)
      }
    });

    this.addonDocumentsToBeUploaded.push(
      {
        "docTypeId": 48,
        "name": "Add On Photo ID",
        "mandatory" : false
      },{
        "docTypeId": 51,
        "name": "Add On Government Photo ID",
        "mandatory" : true
      },{
        "docTypeId": 54,
        "name": "Add On Other Documents",
        "mandatory" : false
      }
    )

    this.addOns.map((addOn) => {
      addOn.documents = this.addonDocumentsToBeUploaded.filter((document) => {
        return addOn.id == document.addOnId
      })
    })

  }


  ClickonInputById(id, type, number) {
    document.getElementById(id+"-"+type+"-"+number).click();
  }

  FileSelected(event, doc, type, number) {
    if (event.target.files) {
      this.selectedFile = event.target.files[0];
      this.CompressandPreviewImage(doc, type, number);
    }

  }

  CompressandPreviewImage(document, type, number) {
    let image = this.selectedFile;

    this.ng2ImgMax.compressImage(image, 2).subscribe(
      result => {
        this.uploadedImage = new File([result], document.docTypeId);
        this.getImagePreview(this.uploadedImage, document.docTypeId, type, number);
        this.compressedImageFiles.push({ file: this.uploadedImage, document: document, identifier : document.docTypeId+'-'+type+'-'+number })
      },
      error => {
        console.log('😢 Oh no!', error);
      }
    );
  }


  getImagePreview(file: File, docId, type, number) {
    const reader: FileReader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.previews[docId+'-'+type+'-'+number] = reader.result
    };
  }

  getImagePreviewIf(id, type, number) {
    return this.previews[id+'-'+type+'-'+number]
  }

  getImagePreviewName(id, type, number) {
    return this.previews[id+'-'+type+'-'+number]
  }

  clearPic(docId, event, type, number) {
    event.preventDefault();
    event.stopPropagation();
    var elem = <HTMLInputElement>document.getElementById(docId+"-"+type+"-"+number)
    elem.value = ""
    delete this.previews[docId+'-'+type+'-'+number]
    this.deletefromArray(docId, type, number);
  }

  deletefromArray(docId, type, number) {
    this.compressedImageFiles = this.compressedImageFiles.filter((compresseddoc) => {
      return compresseddoc.file.name != docId
    })
  }
  GetDocumentsTobeDropped(){
    

    return this.documentsTobeUploaded;
  }

DropDocumentsNotUploaded()
{ 
  var documentsToBeDropped = this.GetDocumentsTobeDropped()
  documentsToBeDropped.map((document)=>{
    document.drop = true
  });
  let documentsToBeDroppedFiltered = []
  documentsToBeDropped.map((document,documentIndex) => {
    if(!this.uploadedId.includes(document.id) && document.docTypeId != 52){
      documentsToBeDroppedFiltered.push(document)
    }
  })

  setTimeout(() => {
    var putBody = {
      "customers": [{
          "documents": documentsToBeDroppedFiltered,
      }]
    }
  
    this._baseService.put('customers/'+this.customerId,putBody).subscribe((data:any)=>{
        if (!data.status.status)
        {
          this.DropDocumentsNotUploaded();
        }
    })
  }, 200)
}

  UploadAllFiles() {
    var counter = 0
    this.uploadingImages = true
    this.UploadImage(counter);
    this.dataEditService.markStepComplete('/upload-documents')
  }


  CheckIfAllMandotryDocumentsAreSelected() {
    var mandatoryBusinessDocuments = this.documentsTobeUploaded.filter((document) => {
      return document.mandatory == true
    })

    var mandatoryAddOnDocuments = this.addonDocumentsToBeUploaded.filter((document) => {
      return document.mandatory == true
    })

    var mandatorydocumentsSelcted = this.compressedImageFiles.filter((_file) => {
      return _file.document.mandatory == true
    })

    if (mandatorydocumentsSelcted.length == (mandatoryAddOnDocuments.length * this.addOns.length) + mandatoryBusinessDocuments.length)
      return true;
    else
      return false;

  }

  UploadImage(counter) {
    if (counter == this.compressedImageFiles.length) {
     // this.dataEditService.RegistrationComplete.next(true);
     this.DropDocumentsNotUploaded();
     this.router.navigateByUrl('/T&C')
    } else {
      var compressedImage = this.compressedImageFiles[counter];
      var fd = new FormData();
      fd.append('file', compressedImage.file, compressedImage.file.name + '.png')
      let httpOptions = {
        headers: new HttpHeaders({ 'Authorization': 'Basic ZWNvbWNhZToxMjM=' })
      };
      let url = ""
      let identifier = compressedImage.identifier.split("-")
      if(identifier[1] == "addon"){
        url = "customers/" + this.customerId + "/upload?docId=" + this.addOnCustomerDocumentDetails[this.customerRegisteredData.addOnCustomers[identifier[2]].id][compressedImage.document.docTypeId]+"&addonId="+this.customerRegisteredData.addOnCustomers[identifier[2]].id
        this.uploadedId.push(parseInt(this.addOnCustomerDocumentDetails[this.customerRegisteredData.addOnCustomers[identifier[2]].id][compressedImage.document.docTypeId], 10))
      }else{
        url = compressedImage.document.url
        let urlSplit = url.split("=")
        this.uploadedId.push(parseInt(urlSplit[1], 10))
      }
      
      this.http.post(this._baseService.baseUrl + url, fd, httpOptions).subscribe(((data: any) => {
        if (data.status.status) {
          counter++;
          this.UploadImage(counter);
        }
        else {
          alert("Oops, Something went Wrong!")
        }
      }))

    }
  }

  goBack(){
    this.router.navigateByUrl('/licence-details')
  }
}
