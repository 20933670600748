import { Component, OnInit } from '@angular/core';
import { BaseService } from '../../services/BaseService';
import { Subscriber } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DatepickerOptions } from 'ng2-datepicker';
import { Router, ResolveEnd } from '@angular/router';
import { FormService } from '../../services/FormService';
import { DataEditService } from 'src/services/Data+EditService';

@Component({
  selector: 'app-licence-details',
  templateUrl: './licence-details.component.html',
  styleUrls: ['./licence-details.component.css']
})
export class LicenceDetailsComponent implements OnInit {

  issueDateOptions: DatepickerOptions = {
    barTitleFormat: 'MMMM YYYY',
    useEmptyBarTitle: false,
    minYear: 1900,
    maxDate: new Date(Date.now()),
  };

  expiryDateOptions: DatepickerOptions = {
    useEmptyBarTitle: false,
    minYear: 1900,
    minDate: new Date(Date.now()),
    barTitleFormat: 'MMMM YYYY'
  };


  constructor(private _baseService: BaseService, private dataEditService: DataEditService, private formService: FormService, private router: Router) { }

  licenceDetailsForm: FormGroup;
  licenceType: FormControl;
  licenceNumber: FormControl;
  ifOther: FormControl;
  licenceIssueDate: FormControl;
  licenceExpiryDate: FormControl;
  gstNumber: FormControl;
  turnover: FormControl;
  panNumber: FormControl;
  legalName: FormControl;
  isLicenceNumberDuplicate: boolean = false
  validgstNumber: boolean = false

  _licenceTypes = [];

  formCreated: boolean;

  ngOnInit() {
    this.getLicenceTypes();
  }

  getLicenceTypes() {
    this._baseService.get('customers/doctypes').subscribe((data: any) => {
      if (data.status.status) {
        this.dataEditService.docTypes = [...data.licenceTypes, ...data.docTypes];
        this._licenceTypes = data.licenceTypes;
        this.createForm();
      }
    })
  }

  gstValidate(event) {
    var that = this;
    if (that.gstNumber.valid && that.gstNumber.value.length == 15) {
      if(that.gstNumber.value.substring(0,2) != that.dataEditService.customer.addresses[0].stateTin){
        alert("The GST Number which you entered does not belongs to "+that.dataEditService.customer.addresses[0].state+" State which you selected.")
        return false
      }
      that._baseService.get('customers/validate?gstin=' + that.gstNumber.value.toUpperCase()).subscribe((data: any) => {
        if (data.status.code == 1200) {
          that.legalName.setValue(data.customers[0].legalName);
          that.dataEditService.customer.customLegalName = data.customers[0].legalName
          that.validgstNumber = true;
        } else {
          that.legalName.setValue("");
          that.validgstNumber = false
        }
      })
    }
  }

  SaveLicenceDetails() {
    var that = this;
    this.CheckLisenceNumber().then(function () {
      if(that.validgstNumber == false && that.gstNumber.value != ""){
        if (!confirm("Invalid GST Number. Do you want to continue without GST.?")) {
          return false
        }
      }
      if(that.validgstNumber == true) {
        if (that.licenceDetailsForm.valid && !that.isLicenceNumberDuplicate) {
          that.SaveDataInService();
          that.RegisterCustomer();
        } else {
          that.formService.validateAllFormFields(that.licenceDetailsForm);
        }
      }else {
        if (that.licenceDetailsForm.valid && !that.isLicenceNumberDuplicate) {
          that.gstNumber.setValue("");
          that.SaveDataInService();
          that.RegisterCustomer();
        } else {
          that.formService.validateAllFormFields(that.licenceDetailsForm);
        }
      }
    });
  }

  RegisterCustomer() {
    this.dataEditService.customer.documents.push(...this.dataEditService.getaddOnDocuments())
    this.dataEditService.markStepComplete('/licence-details')
    this.dataEditService.markStepComplete('/add-on-details')
    this.router.navigateByUrl('/upload-documents')
  }

  createForm() {
    this.createControls();
    this.licenceDetailsForm = new FormGroup({
      licenceType: this.licenceType,
      licenceNumber: this.licenceNumber,
      ifOther: this.ifOther,
      licenceIssueDate: this.licenceIssueDate,
      licenceExpiryDate: this.licenceExpiryDate,
      gstNumber: this.gstNumber,
      turnover: this.turnover,
      panNumber: this.panNumber,
      legalName: this.legalName
    })
    this.formCreated = true;
    this.fillFormFields();
  }

  fillFormFields() {
    this.licenceType.setValue(this.dataEditService.additionalFormDetails.licenceType);
    this.licenceNumber.setValue(this.dataEditService.additionalFormDetails.licenceNumber);
    this.licenceIssueDate.setValue(this.dataEditService.additionalFormDetails.issueDate);
    this.licenceExpiryDate.setValue(this.dataEditService.additionalFormDetails.expirydate);
    this.gstNumber.setValue(this.dataEditService.customer.gstin == "/" ? '' : this.dataEditService.customer.gstin);
    this.legalName.setValue(this.dataEditService.customer.customLegalName)
    if(this.dataEditService.customer.customLegalName != ""){
      this.validgstNumber = true
    }

    this.turnover.setValue(this.dataEditService.customer.annualTurnover);
    this.panNumber.setValue(this.dataEditService.customer.pan);
  }
  createControls() {
    this.licenceType = new FormControl({}, [Validators.required]);
    this.licenceType.setValue("Click here to select");
    this.licenceNumber = new FormControl('', [Validators.required, Validators.pattern("[a-zA-Z0-9]+")]);
    this.ifOther = new FormControl('');
    this.licenceIssueDate = new FormControl('', [Validators.required]);
    this.licenceExpiryDate = new FormControl('', [Validators.required]);
    this.gstNumber = new FormControl('', [Validators.maxLength(15), Validators.minLength(15)])
    this.turnover = new FormControl('')
    this.panNumber = new FormControl('')
    this.legalName = new FormControl('')
  }

  CheckLisenceNumber() {
    var _this = this;
    let licenceNum = this.licenceNumber.value;
    return new Promise(function (resolve, reject) {
      if (licenceNum == "" || licenceNum == null) {
        resolve();
        _this.isLicenceNumberDuplicate = false
      }
      _this._baseService.get('/customers/search?q=' + licenceNum).subscribe((data: any) => {
        if (data.status.code == 1029) {
          resolve();
          _this.isLicenceNumberDuplicate = false
        }
        else if (data.customers.length > 0) {
          resolve();
          _this.isLicenceNumberDuplicate = true
        }

      });
    })
  }


  SaveDataInService() {

    if (this.dataEditService.additionalFormDetails.licenceType) {
      var _docTypeId = JSON.parse(this.dataEditService.additionalFormDetails.licenceType).id;
      var index = this.dataEditService.customer.documents.findIndex(doc => doc.docTypeId == _docTypeId);
      this.dataEditService.customer.documents.splice(index, 1)
    }

    var issuedate = new Date(this.licenceIssueDate.value);
    var expirydate = new Date(this.licenceExpiryDate.value);
    this.SaveAdditionalFormData();
    var document = {
      "docTypeId": JSON.parse(this.licenceType.value).id,
      "name": JSON.parse(this.licenceType.value).name,
      "number": this.licenceNumber.value,
      "issueDate": issuedate.getFullYear() + "-" + (issuedate.getMonth() + 1) + "-" + issuedate.getDate(),
      "expiryDate": expirydate.getFullYear() + "-" + (expirydate.getMonth() + 1) + "-" + expirydate.getDate(),
    }
    this.dataEditService.customer.documents.push(document);

    this.dataEditService.customer.gstin = this.gstNumber.value ? this.gstNumber.value : "/"
    this.dataEditService.customer.pan = this.panNumber.value ? this.panNumber.value : ""
    this.dataEditService.customer.annualTurnover = this.turnover.value ? this.turnover.value : ""
  }
  SaveAdditionalFormData() {
    var issuedate = new Date(this.licenceIssueDate.value);
    var expirydate = new Date(this.licenceExpiryDate.value);
    this.dataEditService.additionalFormDetails.issueDate = issuedate;
    this.dataEditService.additionalFormDetails.expirydate = expirydate;
    this.dataEditService.additionalFormDetails.licenceType = this.licenceType.value;
    this.dataEditService.additionalFormDetails.licenceNumber = this.licenceNumber.value;
  }

  goBack(){
    this.router.navigateByUrl('/primary-cardholder-details')
  }

}
