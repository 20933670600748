import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseService } from 'src/services/BaseService';
import { DataEditService } from 'src/services/Data+EditService';
import { FormService } from 'src/services/FormService';

@Component({
  selector: 'app-search-customer',
  templateUrl: './search-customer.component.html',
  styleUrls: ['./search-customer.component.css']
})
export class SearchCustomerComponent implements OnInit {
  customerFound: boolean;
  searchCustomerbtn: boolean = true
  customerData: any
  searchOTPSent: any = false;
  mobile: FormControl
  otp: FormControl
  searchForm: FormGroup
  otpvalidateForm: FormGroup
  identifierType = "MOBILE"
  brand = "SIAMDEMO"
  @Input() showSubHeader = true;
  angryemoji: boolean = false;
  
  constructor(
    private _baseService: BaseService,
    private dataEditService: DataEditService,
    private router: Router,
    private formService: FormService
  ) { }

  ngOnInit() {
    this.createForms();
    localStorage.removeItem("ROmobilenumber");
    localStorage.removeItem("ROcustomerid");
    localStorage.removeItem("newMobile");
    localStorage.removeItem("loginsucessSessionID");
    localStorage.removeItem("ROtoken");
    localStorage.removeItem("CustomerData");
  }

  createForms() {
    this.mobile = new FormControl('', [
      Validators.required,
      Validators.maxLength(10),
      Validators.minLength(10),
      Validators.pattern('^[6-9][0-9]{9}$')
    ])
    this.searchForm = new FormGroup({
      mobile: this.mobile
    })

    this.otp = new FormControl('', [
      Validators.required
    ])

    this.otpvalidateForm = new FormGroup({
      otp: this.otp
    })
  }

  ResendOTP(event) {
    this.issueOTPrequest();
    event.target.hidden = true
    setTimeout(() => {
      event.target.hidden = false
    }, 5000)
  }

  goToPage(pageName) {
    this.router.navigateByUrl(pageName);
  }

  sendOrVerifyOTP() {
    if (this.searchOTPSent) {
      if (this.otpvalidateForm.valid)
        this.verifyOTPRequest()
      else
        this.formService.validateAllFormFields(this.otpvalidateForm)
    }
    else {
      if (this.searchForm.valid)
        this.issueOTPrequest()
      else
        this.formService.validateAllFormFields(this.searchForm)
    }
  }

  issueOTPrequest() {
    let postBody = {
      mobile: 91 + this.mobile.value,
      brand: this.brand,
    }

    this._baseService.mobileApi('auth/v1/web/token/generate',postBody).subscribe((data: any) => {
      if (data.status.success == true) {
        let sessionId = data.user.sessionId
        let otpdata = {
          mobile: 91 + this.mobile.value,
          brand: this.brand,
          sessionId: sessionId
        }
        localStorage.setItem("loginsucessSessionID", data.user.sessionId)
        this._baseService.mobileApi('auth/v1/web/otp/generate',otpdata).subscribe((otpdata: any) => {
          if (otpdata.status.success == true) {
            this.searchOTPSent = true;
          }else {
            alert("something went wrong")
          }
        })
      }else {
        alert("something went wrong")
      }
    })
  }

  verifyOTPRequest() {
    // this._baseService.get('/customers/validateotp?mobile=' + this.mobile.value + '&otp=' + this.otp.value).subscribe((data: any) => {
    let otpvalidatedata = {
      mobile: 91 + this.mobile.value,
      brand: this.brand,
      sessionId: localStorage.getItem('loginsucessSessionID'),
      otp: this.otp.value
    }
    this._baseService.mobileApi('auth/v1/web/otp/validate', otpvalidatedata).subscribe((otpvalidatedata: any) => {
      if (otpvalidatedata.status.success) {
          localStorage.setItem("ROtoken", otpvalidatedata.auth.token)
          this.searchCustomerstatus();
      }
      // else if(otpvalidatedata.status.message == "No match found in search" && "Invalid Session.") {
      //     this.searchOTPSent = false;
      //     alert(otpvalidatedata.status.message)
      // }
      else {
        alert(otpvalidatedata.status.message)
      }
    })
  }

  searchCustomerstatus(){
    this.customerFound = false;
    let token = localStorage.getItem('ROtoken')
    let mobileheader = 91 + this.mobile.value
    // Mobile API

    this._baseService.crmapigetCustomer('search?q=91' + this.mobile.value, token, mobileheader, this.brand ).subscribe((data: any) => {
      if(data.status.status) {
        this.customerFound = true;
        localStorage.setItem('ROmobilenumber', this.mobile.value);
        this.router.navigateByUrl('/dashboard')
        this.customerData = data.customers[0]
        localStorage.setItem('CustomerData', this.customerData);
      } else {
        this.customerFound = false;
        this.angryemoji = true;
        this.searchOTPSent = false;
        this.otp.setValue("");
        // alert(data.status.message);
        // window.location.reload();
        // this.router.navigateByUrl('/customer-registration')
      }
    })

    // SiamMacro API

    // this._baseService.get('customers/search?q=' + this.mobile.value).subscribe((data: any) => {
    //   if (data.status.status) {
    //     this.customerFound = true;
    //     localStorage.setItem('ROmobilenumber', this.mobile.value);
    //     this.router.navigateByUrl('/dashboard')
    //     this.customerData = data.customers[0]
    //     localStorage.setItem('CustomerData', this.customerData);
    //   } else {
    //     this.customerFound = false;
    //     alert(data.status.message);
    //   }
    // })


  }

  onlyten(event){
    if(event.target.value.length > 9){
      event.preventDefault();
    }
  }
}
