import { Component, OnInit } from '@angular/core';
import { DatepickerOptions } from 'ng2-datepicker';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormService } from '../../services/FormService';
import { Router } from '@angular/router';
import { DataEditService } from 'src/services/Data+EditService';
import { BaseService } from '../../services/BaseService';

@Component({
  selector: 'app-primary-cardholder-details',
  templateUrl: './primary-cardholder-details.component.html',
  styleUrls: ['./primary-cardholder-details.component.css']
})
export class PrimaryCardholderDetailsComponent implements OnInit {

  constructor(private _baseService: BaseService, private formService:FormService,private router:Router,private dataEditService:DataEditService) { }

  dob:FormControl
  gender:FormControl
  businessOwnerName:FormControl
  cardHolderForm:FormGroup
  duplicateMobileFound = 0
  duplicateMobiles = []
  duplicateEmailFound = 0
  duplicateEmails = []

  dobOptions:DatepickerOptions={
    minYear: 1900,
    maxDate : new Date(Date.now()),
    useEmptyBarTitle:false,
    barTitleFormat: 'MMMM YYYY'
  }

  addOnSelected: boolean = false
  addOnNumberForm: FormGroup;
  numberofAddons: FormControl;
  addonForm: FormGroup;
  addOns = [];

  dobDateOptions: DatepickerOptions = {
    maxYear: new Date().getFullYear() + 1,
    maxDate: new Date(Date.now()),
    useEmptyBarTitle:false,
    barTitleFormat: 'MMMM YYYY'
  }

  ngOnInit() {
    this.createForm();
  }

  createForm(){
    this.createControls();
    this.cardHolderForm = new FormGroup({
      businessOwnerName:this.businessOwnerName,
      gender:this.gender,
      dob:this.dob
    })
    this.numberofAddons = new FormControl();
    this.addOnNumberForm = new FormGroup({
      numberofAddons: this.numberofAddons
    })

    this.addonForm =  new FormGroup({});
    this.cleanAddonDocs();
    this.addOns=[];
    // this.dataEditService.customer.addOnCustomers = []
    this.subsrcibeToAddOnNumberValueChanges();
    this.numberofAddons.setValue("1");
    this.fillFormFields();
  }

  cleanAddonDocs(){
    var _looper = 0;
    this.dataEditService.addonDocuments.documents = []
    while(_looper!=-1)
    {
      var index = this.dataEditService.customer.documents.findIndex(doc=>doc.docTypeId == 48 || doc.docTypeId == 51 || doc.docTypeId == 54)
      if (index>=0)
      this.dataEditService.customer.documents.splice(index,1)
      
      _looper=index;
    }
  }

  subsrcibeToAddOnNumberValueChanges() {
    this.numberofAddons.valueChanges.subscribe(value => {

      var count = Number(value)
      var start = 1;
      if (this.addOns.length > count) {
        var length = this.addOns.length;
        
        for (var k = 0; k < ((length - count)); k++)
        {
        this.addOns.pop();
        this.removeFromForm(length-k)
      }
      return;
      }
      if (this.addOns.length < count) {
        start = this.addOns.length + 1
      }
      for (var i = start; i <= count; i++) {
        this.addToForm(i);
        this.addOns.push({ memberNumber: i })
      }
    })
  }

  removeFromForm(i){
 
    this.addonForm.removeControl('firstName-'+i)
    this.addonForm.removeControl('lastName-' + i )
    this.addonForm.removeControl('relation-' + i )
    this.addonForm.removeControl('landline-' + i )
    this.addonForm.removeControl('dob-' + i )
    this.addonForm.removeControl('mobile-' + i )
    this.addonForm.removeControl('email-' + i)

  }

  addToForm(number) {
 this.getFormControl(number)
  }

  getFormControl(number) {  
    let controls = {}
      let i = number;
      // fname: ['', [
      //   Validators.required,
      //   Validators.pattern(
      //     '^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_ \.]*$'
      //   )
      // ]],
      this.addonForm.addControl('firstName-'+i,new FormControl('', 
      [Validators.required,
      Validators.pattern('^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_ \.]*$')]))
      this.addonForm.addControl('lastName-' + i , new FormControl('',
      [Validators.pattern('^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_ \.]*$')]))
      this.addonForm.addControl('relation-' + i , new FormControl('', Validators.required))
      this.addonForm.addControl('landline-' + i , new FormControl())
      this.addonForm.addControl('dob-' + i , new FormControl())
      this.addonForm.addControl('mobile-' + i , new FormControl('', 
      [Validators.required, 
      Validators.maxLength(10),
      Validators.minLength(10),
      Validators.pattern('^[6-9][0-9]{9}$')]))
       this.addonForm.addControl('email-' + i, new FormControl('', Validators.pattern("[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}")))
    return controls
  }


  fillFormFields(){
    if (this.dataEditService.customer.firstName != "")
    this.businessOwnerName.setValue(this.dataEditService.customer.firstName + " " +this.dataEditService.customer.lastName);
    this.gender.setValue(this.dataEditService.customer.gender);
    this.dob.setValue(this.dataEditService.additionalFormDetails.date_of_birth);
    this.autoFillAddons();
  }

  autoFillAddons(){
    if(this.dataEditService.customer.addOnsSelected){
      this.addOnSelected = true
      this.numberofAddons.setValue(this.dataEditService.customer.addOnCustomers.length);
      this.dataEditService.customer.addOnCustomers.map(addon => {
        this.addonForm.controls["firstName-"+addon.serial].setValue(addon.firstName)
        this.addonForm.controls["lastName-"+addon.serial].setValue(addon.lastName)
        this.addonForm.controls["relation-"+addon.serial].setValue(addon.relation)
        this.addonForm.controls["mobile-"+addon.serial].setValue(addon.mobile)
        this.addonForm.controls["dob-"+addon.serial].setValue(addon.dob)
        this.addonForm.controls["email-"+addon.serial].setValue(addon.email)
      })
    }
  }

  createControls(){
    this.businessOwnerName = new FormControl('',[Validators.required, Validators.pattern("[A-Za-z\\s]*")]);
    this.gender = new FormControl();
    this.dob = new FormControl();
    this.gender.setValue('');
  }

  SavePrimaryCardHolder(){
    if (this.cardHolderForm.valid){
      this.SaveDataInService();
      this.SaveOrSkipAddOn()
    }else
    this.formService.validateAllFormFields(this.cardHolderForm)
  }

  SaveDataInService(){
    
    if (this.dob.value){
    var date_of_birth = new Date(this.dob.value)
      this.dataEditService.additionalFormDetails.date_of_birth = date_of_birth;
  }

    this.dataEditService.customer.firstName = this.businessOwnerName.value.split(/ (.+)/)[0]
    this.dataEditService.customer.lastName = this.businessOwnerName.value.split(/ (.+)/)[1] ? this.businessOwnerName.value.split(/ (.+)/)[1] : " "
    this.dataEditService.customer.gender = this.gender.value
    if (date_of_birth){
    this.dataEditService.customer.dob =  date_of_birth.getFullYear()+"-"+(date_of_birth.getMonth()+1) + "-" + date_of_birth.getDate()
    }
  }

  SaveOrSkipAddOn() {
    if (!this.addOnSelected) {
      this.skip();
    }
    else {
      if (this.addonForm.valid) {
        this.dataEditService.customer.addOnCustomers = [];
        this.dataEditService.addonDocuments.documents = [];
        for (var i = 1; i <= this.addOns.length; i++) {

          let currentMobile = this.addonForm.get('mobile-' + i).value
          let currentEmail = this.addonForm.get('email-' + i).value

          if(this.addonForm.get('mobile-' + i).value == this.dataEditService.customer.mobile){
            alert("Member "+i+" Mobile number cannot be same as primary card holder mobile number")
            return false
          }

          if(currentEmail == this.dataEditService.customer.email && currentEmail!="" && this.dataEditService.customer.email != ""){
            alert("Member "+i+" Email Id cannot be same as primary card holder Email Id")
            return false
          }

          if(i>1){
            for (var j = i-1; j >= 1; j--) {
              if(this.addonForm.get('mobile-' + i).value == this.addonForm.get('mobile-' + j).value){
                alert("Member "+i+" Mobile number cannot be same as Member "+j+" mobile number")
                return false
              }

              if(this.addonForm.get('email-' + i).value == this.addonForm.get('email-' + j).value && this.addonForm.get('email-' + i).value != "" && this.addonForm.get('email-' + j).value != ""){
                alert("Member "+i+" Email Id cannot be same as Member "+j+" Email Id")
                return false
              }
            }
          }

          //checking for duplicate mobile
          this._baseService.get('customers/search?q=91' + currentMobile).subscribe((data: any) => {
            if(data.status.status == true){
              this.duplicateMobileFound++
              this.duplicateMobiles.push(currentMobile)
            }
          })

          //checking for duplicate email
          if(currentEmail != ""){
            this._baseService.get('customers/search?q=' + currentEmail+'&email=true').subscribe((data: any) => {
              if(data.status.status == true){
                this.duplicateEmailFound++
                this.duplicateEmails.push(currentEmail)
              }
            })
          }

          var date_of_birth = ""
          
          if (this.addonForm.get('dob-' + i).value) {
            var dob = new Date(this.addonForm.get('dob-' + i).value)
            date_of_birth = dob.getFullYear() + "-" + (dob.getMonth() + 1) + "-" + dob.getDate()
          }

          let addon = {
            "firstName": this.addonForm.get('firstName-' + i).value,
            "lastName": this.addonForm.get('lastName-' + i).value,
            "landline": this.addonForm.get('landline-' + i).value ? this.addonForm.get('landline-' + i).value : " ",
            "mobile": this.addonForm.get('mobile-' + i).value,
            "dob": date_of_birth,
            "relation": this.addonForm.get('relation-' + i).value,
            "email": this.addonForm.get('email-' + i).value,
            "serial": i
          }

          if(addon.email == ""){
            delete addon.email
          }

          this.dataEditService.customer.addOnsSelected = true
          this.dataEditService.customer.addOnCustomers.push(addon);
          
          var document_1 = {
            "docTypeId": 48,
            "name": "Add On Photo ID",
            "serial": i
          }

          var document_2 = {
            "docTypeId": 51,
            "name": "Add On Government Photo ID",
            "serial": i
          }

          var document_3 = {
            "docTypeId": 54,
            "name": "Add On Other Documents",
            "serial": i
          }

          this.dataEditService.addonDocuments.documents.push(document_1, document_2, document_3);
        }
        // this.RegisterCustomer();
        setTimeout(()=>{
          if(this.duplicateMobileFound == 0){
            if(this.duplicateEmailFound == 0){
              this.redirect();
            }else{
              alert(this.duplicateEmails.join(',')+" email's are already exists")
              this.duplicateEmailFound = 0
              this.duplicateEmails = []
              return false
            }
          }else{
            alert(this.duplicateMobiles.join(',')+" mobile numbers are already exists")
            this.duplicateMobileFound = 0
            this.duplicateMobiles = []
            return false
          }
        }, 500)
      }
      else {
        this.formService.validateAllFormFields(this.addonForm);
      }
    }
  }

  skip() {
    // this.RegisterCustomer();
    this.redirect();
  }

  redirect(){
    this.dataEditService.markStepComplete('/primary-cardholder-details')
    this.router.navigateByUrl('/licence-details');
  }

  searchCustomerstatus(){
    // this._baseService.get('customers/search?q=91' + this.mobileNumber.value).subscribe((data: any) => {
    //   if(data.status.status == true){
    //     alert("Customer already exists with same mobile number")
    //     return false
    //   }else{
    //     this.issueOTPrequest()
    //   }
    // })
  }

  // RegisterCustomer() {
  //   this.dataEditService.customer.documents.push(...this.dataEditService.getaddOnDocuments())
  //   this.dataEditService.markStepComplete('/add-on-details')
  //   this.router.navigateByUrl('/upload-documents')
  // }

  goBack(){
    this.router.navigateByUrl('/business-details')
  }

  onlyten(event){
    if(event.target.value.length > 9){
      event.preventDefault();
    }
  }
}
