
import { Component, OnInit } from '@angular/core';
import { BaseService } from '../../../services/BaseService';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FormService } from '../../../services/FormService';
import { DataEditService } from 'src/services/Data+EditService';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  customerFound: boolean;
  apiCallComplete:boolean = false
  searchCustomerbtn: boolean = true
  customerData: any
  OTPSent: any = false;
  mobileNumber: FormControl
  otp: FormControl
  mobileNumberForm: FormGroup
  otpForm: FormGroup
  barcodevalue:any
  customerbyCustomerID:any
  barcodecolor="#444444"
  oldmobile: string;
  userMobile = localStorage.getItem('ROmobilenumber');
  authtoken = localStorage.getItem('ROtoken');
  brand = "SIAMDEMO"
  customer_id:any
  constructor(
    private _baseService: BaseService,
    private dataEditService: DataEditService,
    private router: Router,
    private formService: FormService
  ) { }

  ngOnInit() {
    let mobile = 91 + this.userMobile
    this._baseService.crmapigetCustomer('search?q=' + mobile, this.authtoken, mobile, this.brand ).subscribe((data: any) => {
      if(data.status.status) {
      this.apiCallComplete = true
        this.customerFound = true;
        this.customerData = data.customers[0]
        this.customer_id = this.customerData.id
        localStorage.setItem("ROcustomerid", this.customer_id)
        this.barcodevalue = this.customerData.cardNumber
        this.oldmobile = this.customerData.mobile.slice(2);
        if(this.customerData.approvalStatus == "APPROVED") {
          this._baseService.crmapigetCustomer('id?id=' + this.customerData.id, this.authtoken, mobile, this.brand).subscribe((newdata: any) => {
            if (newdata.status.status) {
              this.customerbyCustomerID = newdata.customers[0]
            }
          })
        }
      } else {
        this.customerFound = false;
        alert(data.status.message);
        this.router.navigateByUrl('/search');
      }
    })
  }

  goToPage(pageName) {
    this.router.navigateByUrl(pageName);
  }
}
