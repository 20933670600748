import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

// QA
const baseUrl = "https://sol-qa.capillarytech.com/makro/v1/"
const webAPI = "https://apac2-api-gateway.capillarytech.com/mobile/v2/api/"
const serverauthURL = "https://apac2-auth-api.capillarytech.com/"
const AuthHeaderValue = 'Basic ZWNvbWNhZToxMjM='
const AuthHeaderValueCustomerget = 'Basic c3RvcmVfbWFuYWdlcl8xQGxvdHMuY29tOmNhcGlsbGFyeQ=='

// while moving production please change AuthHeaderValue and AuthHeaderValueCustomerget

// Prod
// const baseUrl = "http://lots.capillarytech.com/makro/v1/"
// const webAPI = "https//apac2-api-gateway.capillarytech.com/mobile/v2/api/"
// const serverauthURL = "https://apac2-auth-api.capillarytech.com/"
// const AuthHeaderValue = 'Basic ZWNvbWNhZToxMjM='
// const AuthHeaderValueCustomerget = 'Basic c3RvcmVfbWFuYWdlcl8xQGxvdHMuY29tOmNhcGlsbGFyeQ=='


@Injectable()
export class BaseService {

    baseUrl = baseUrl;
    webAPI = webAPI
    constructor(
        private http: HttpClient, 
        private router: Router
    ) {

    }

    get(url) {
        let httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Basic c3RvcmVfbWFuYWdlcl8xQGxvdHMuY29tOmNhcGlsbGFyeQ==" })
        };
        return this.http.get(baseUrl + url, httpOptions);
    }

    getbyCustomerID(url) {
        let httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': AuthHeaderValueCustomerget })
        };
        return this.http.get(baseUrl + url, httpOptions);
    }


    post(url, postBody) {
        let httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': AuthHeaderValue })
        };
        return this.http.post(baseUrl + url, postBody, httpOptions)
    }

    mobileApi(url, postBody) {
        let httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json'})
        };
        return this.http.post(serverauthURL + url, postBody, httpOptions)
    }

    crmapigetCustomer(url, token, mobileheader, brand) {
        let httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'cap_authorization': token, 'cap_mobile': mobileheader, 'cap_brand':brand })
        };
        return this.http.get(webAPI + url, httpOptions);
    }

    put(url, putBody) {
        let httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': AuthHeaderValue })
        };
        return this.http.put(baseUrl + url, putBody, httpOptions)
    }

    mobilechangeput(url, putBody) {
        let httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': AuthHeaderValueCustomerget })
        };
        return this.http.put(baseUrl + url, putBody, httpOptions)
    }
}
